.modal1 {
    z-index: 100;
    position: fixed;
    top: 22vh;
    left: 5%;
    width: 90%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
  }
  