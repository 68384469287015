@import url(https://fonts.googleapis.com/css?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-width {
  width: -webkit-fit-content;
  width: fit-content;
  display: inline-block;
}

.logo--img{
  width: 30px;
}

.Offers-body {
  padding: 12px;
}

.extrapoint {
  opacity: 0.75;
}
.extrapointh5 {
  margin-bottom: 0%;
  margin-top: 5px;
}

.getpointsdiv {
  padding: 0%;
}
.getpointsul {
  padding: 8px;
  padding-bottom: 3%;
}
.offertxt {
  margin-left: 8px;
  margin-top: 10px;
}
.modaldiv {
  margin-bottom: 0.8rem;
}
.modaldivi {
  font-size: 200%;
  color: #007bf0;
}
.modaldivicolor1 {
  color: brown;
}
.modaldivicolor2 {
  color: darkblue;
}
.modaldivbutton {
  margin-left: auto;
  margin-right: auto;
}
.cardh5txt {
  text-align: center;
}
.cardp {
  text-align: center;
  font-weight: bold;
}
.cardh5 {
  font-size: 80%;
}
.modaldiv2 {
  padding-bottom: 10px;
}

/*importing Google font*/

.logo {
  font-family: "Pacifico", cursive;
  font-weight: bold;
}

.main-navigation {
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2);
}
hr.style13 {
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8b8b inset;
}
.extra {
  font-weight: normal;
}

.card {
  margin-bottom: 1rem;
}

.centerelement {
  position: relative;
}

/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.progress {
  width: 100px;
  height: 100px;
  background: none;
  position: relative;
  margin-bottom: 10px;
}

.progress::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  transform-origin: center right;
}

.progress .progress-value {
  position: absolute;
  top: 0;
  left: 0;
}
.rounded-lg {
  border-radius: 1rem;
}

.text-gray {
  color: #aaa;
}

div.h4 {
  line-height: 1rem;
}

hr.solid {
  border-top: 2px solid #999;
  color: black;
}

.spacejustified {
  display: flex;
  justify-content: space-between;
}

.spane {
  font-size: 100%;
  margin-top: 10%;
}

.entry-social {
  margin-bottom: 20px;
  color: rgb(126, 126, 126);
  display: block;
  font-family: "Open Sans", Tahoma, Verdana, Arial, sans-serif, Faruma, Faseyha;
  font-size: 14px;
  font-weight: normal;
  height: auto;
  line-height: 23.799999237060547px;
  margin-bottom: 20px;
  width: 100%;
  width: 100%;
  float: left;
  border: solid 0px #aaa;
  text-align: center;
}

.entry-social div {
  display: block;
  width: 200px;
  margin: 2px;
  display: inline-block;
}

.entry-social a {
  text-decoration: none;
  display: block;
  padding-left: 20px;
  color: #ffffff !important;
  font-weight: bold;
  transition: background-color 0.3s;
}

.entry-social .fb a {
  padding: 7px 10px 7px 26px;
  background: #3b5999
    url("https://3.bp.blogspot.com/-yqD2363XuAo/UeDk98twSlI/AAAAAAAAArg/kXIzFBWmczM/s1600/fb14.png")
    no-repeat 10px center;
}

.entry-social .twitter a {
  padding: 7px 10px 7px 32px;
  background: #01bbf6
    url("https://3.bp.blogspot.com/-0u8P4VQmEeU/UeDlVdilwcI/AAAAAAAAAro/lGTnlvHGyVw/s1600/twitter14.png")
    no-repeat 8px center;
}

.entry-social .fb a:hover {
  background-color: rgb(50, 75, 129);
}
.entry-social .twitter a:hover {
  background-color: rgb(1, 159, 211);
}

/* ####################### timeligne ######################### */

.hexa {
  border: 0px;
  float: left;
  text-align: center;
  height: 35px;
  width: 60px;
  font-size: 22px;
  background: #f0f0f0;
  color: #3c3c3c;
  position: relative;
  margin-top: 15px;
}

.hexa:before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 15px solid #f0f0f0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  top: -15px;
}

.hexa:after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 15px solid #f0f0f0;
  bottom: -15px;
}

.timeline {
  position: relative;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  list-style-type: none;
}

.timeline:before {
  position: absolute;
  left: 50%;
  top: 0;
  content: " ";
  display: block;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background: rgb(213, 213, 213);
  background: linear-gradient(
    to bottom,
    rgba(213, 213, 213, 0) 0%,
    rgb(213, 213, 213) 8%,
    rgb(213, 213, 213) 92%,
    rgba(213, 213, 213, 0) 100%
  );
  z-index: 5;
}

.timeline li {
  padding: 2em 0;
}

.timeline .hexa {
  width: 16px;
  height: 10px;
  position: absolute;
  background: #00c4f3;
  z-index: 5;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -30px;
  margin-top: 0;
}

.timeline .hexa:before {
  border-bottom: 4px solid #00c4f3;
  border-left-width: 8px;
  border-right-width: 8px;
  top: -4px;
}

.timeline .hexa:after {
  border-left-width: 8px;
  border-right-width: 8px;
  border-top: 4px solid #00c4f3;
  bottom: -4px;
}

.direction-l,
.direction-r {
  float: none;
  width: 100%;
  text-align: center;
}

.flag-wrapper {
  text-align: center;
  position: relative;
}

.flag {
  position: relative;
  display: inline;
  background: rgb(255, 255, 255);
  font-weight: 600;
  z-index: 15;
  padding: 6px 10px;
  text-align: left;
  border-radius: 5px;
}

.direction-l .flag:after,
.direction-r .flag:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -15px;
  height: 0;
  width: 0;
  margin-left: -8px;
  border: solid transparent;
  border-bottom-color: rgb(255, 255, 255);
  border-width: 8px;
  pointer-events: none;
}

.direction-l .flag {
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.direction-r .flag {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
}

.time-wrapper {
  display: block;
  position: relative;
  margin: 4px 0 0 0;
  z-index: 14;
  line-height: 1em;
  color: #fff;
}

.direction-l .time-wrapper {
  float: none;
}

.direction-r .time-wrapper {
  float: none;
}

.time {
  background: #00c4f3;
  display: inline-block;
  padding: 8px;
}

.desc {
  position: relative;
  margin: 1em 0 0 0;
  padding: 1em;
  background: rgb(254, 254, 254);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  z-index: 15;
}

.direction-l .desc,
.direction-r .desc {
  position: relative;
  margin: 1em 1em 0 1em;
  padding: 1em;
  z-index: 15;
}

@media (min-width: 768px) {
  .timeline {
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .timeline li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  .timeline .hexa {
    left: -28px;
    right: auto;
    top: 8px;
  }

  .timeline .direction-l .hexa {
    left: auto;
    right: -28px;
  }

  .direction-l {
    position: relative;
    width: 310px;
    float: left;
    text-align: right;
  }

  .direction-r {
    position: relative;
    width: 310px;
    float: right;
    text-align: left;
  }

  .flag-wrapper {
    display: inline-block;
  }

  .flag {
    font-size: 18px;
  }

  .direction-l .flag:after {
    left: auto;
    right: -16px;
    top: 50%;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(254, 254, 254);
    border-width: 8px;
  }

  .direction-r .flag:after {
    top: 50%;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(254, 254, 254);
    border-width: 8px;
    left: -8px;
  }

  .time-wrapper {
    display: inline;
    vertical-align: middle;
    margin: 0;
  }

  .direction-l .time-wrapper {
    float: left;
  }

  .direction-r .time-wrapper {
    float: right;
  }

  .time {
    padding: 5px 10px;
  }

  .direction-r .desc {
    margin: 1em 0 0 0.75em;
  }
}

@media (min-width: 992px) {
  .timeline {
    width: 800px;
    margin: 0 auto;
    margin-top: 20px;
  }

  .direction-l {
    position: relative;
    width: 380px;
    float: left;
    text-align: right;
  }

  .direction-r {
    position: relative;
    width: 380px;
    float: right;
    text-align: left;
  }
}
.logo--img{
    width: 37px;
  }
.app-title {
  text-align: center;
  font-size: x-large
}

.teamSelector { cursor: pointer; }
.navBar_bottom {
    padding: 0px;
}
footer
{
    padding: 2rem 0;
}

footer .container
{
    display: flex;
    flex-direction: column!important;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.3rem;
    flex-wrap: wrap;
    margin-top: 0;
}

.footer-nav ul
{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    font-size: 12px;
}

.footer-nav ul li
{
    margin-right: 1.1rem;
}

.footer-nav ul li a
{
    color: #003569;
}

.copyright-notice
{
    color: #999999;
    font-size: 12px;

}
.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

  .form-control--invalid input,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffd1d1;
  }

  .form-control-auth {
      display: block;
  }
  .form-control-auth input{
      width: 100%;
}

.input-form {
  font-size: 14px;
  font-weight: 500;
}

.invalid-p {
    display: block;
    color: red;
    font-size: 12px;
    margin-bottom: 8px;
  }
  




.errorDiv{
    color: white;
    width: 100%;
    font-size: 12px;
    background-color: #F35A6C;
    margin: 8px;
    font-weight: 700;
    padding: 8px;
}
#errorId {
    margin: 8px auto;
}
.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

#loading--points{
  width: 100%;
  height: 100%;
}

#loading--points:after{
  width: 100%;
  height: 100%;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #510077;
  border-color: #510077 transparent #510077 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.562);
  display: flex;
  justify-content: center;
  z-index:5;
  align-items: center;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Facebook */
.loginBtn--facebook {
    background-color: #4C69BA;
    background-image: linear-gradient(#4C69BA, #3B55A0);
    /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
    text-shadow: 0 -1px 0 #354C8C;
  }
  .loginBtn--facebook:before {
    border-right: #364e92 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
  }
  .loginBtn--facebook:hover,
  .loginBtn--facebook:focus {
    background-color: #5B7BD5;
    background-image: linear-gradient(#5B7BD5, #4864B1);
  }
  
  
  /* Google */
  .loginBtn--google {
    /*font-family: "Roboto", Roboto, arial, sans-serif;*/
    background: #DD4B39;
    
  }
  .loginBtn--google:before {
    border-right: #BB3F30 1px solid;
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
  }
  .loginBtn--google:hover,
  .loginBtn--google:focus {
    background: #E74B37;
  }


/*password reset styling*/
.password-reset
{
    font-size: 1.5rem;
    color: #2383e2;
    margin-top: 10px;
}


#signup-btn{
    margin-left: 5px;
    color: #3897f0;
    font-weight: 500;
    border:none;
    background-color: inherit;
    font-size: 16px;
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
  }
.modal1 {
    z-index: 100;
    position: fixed;
    top: 22vh;
    left: 5%;
    width: 90%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 8px;
  }
  
.modal--refer{
    text-align: center;
    font-size: 15px;
    padding: 20px 30px 10px 30px ;
    
}

.modal--refer-title{
    text-align: center;
}

.modal-refer-i{
    margin-right: 20px;
    font-size: 30px;
}
.btn-refer{
    font-size: medium;
    margin: 5px;
}

.modal-points{
    font-size: large;
}

.footer-refer{
    text-align: center;
    display: inline-block;
}

.input--form{
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    text-align: center;
}

#modal-refer-id{
    left: 10%;
    width: 80%;
}
.android-title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding: 30px 5px 5px 5px;
}
.android-subtitle {
    color: gray;
    text-align: center;
    padding: 5px;
}
.android-img{
    margin: 60px;
}
.android-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 90px;
}
.privacyH2 {
    padding: 5px;
    margin-top: 20px;
    text-align: center;
    line-height: 1.6;
}

.privacyH3 {
    padding: 5px;
    text-align: left;
    line-height: 1.6;
}

.privacyH4 {
    padding: 5px;
    text-align: left;
    line-height: 1.6;
}


.privacyP1 {
    padding: 0 10px;
    text-align: justify;
    line-height: 1.6;
}


