.privacyH2 {
    padding: 5px;
    margin-top: 20px;
    text-align: center;
    line-height: 1.6;
}

.privacyH3 {
    padding: 5px;
    text-align: left;
    line-height: 1.6;
}

.privacyH4 {
    padding: 5px;
    text-align: left;
    line-height: 1.6;
}


.privacyP1 {
    padding: 0 10px;
    text-align: justify;
    line-height: 1.6;
}

