

/*password reset styling*/
.password-reset
{
    font-size: 1.5rem;
    color: #2383e2;
    margin-top: 10px;
}


#signup-btn{
    margin-left: 5px;
    color: #3897f0;
    font-weight: 500;
    border:none;
    background-color: inherit;
    font-size: 16px;
}