footer
{
    padding: 2rem 0;
}

footer .container
{
    display: flex;
    flex-direction: column!important;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.3rem;
    flex-wrap: wrap;
    margin-top: 0;
}

.footer-nav ul
{
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    font-size: 12px;
}

.footer-nav ul li
{
    margin-right: 1.1rem;
}

.footer-nav ul li a
{
    color: #003569;
}

.copyright-notice
{
    color: #999999;
    font-size: 12px;

}