
  .form-control--invalid input,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffd1d1;
  }

  .form-control-auth {
      display: block;
  }
  .form-control-auth input{
      width: 100%;
}

.input-form {
  font-size: 14px;
  font-weight: 500;
}

.invalid-p {
    display: block;
    color: red;
    font-size: 12px;
    margin-bottom: 8px;
  }
  



