.android-title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding: 30px 5px 5px 5px;
}
.android-subtitle {
    color: gray;
    text-align: center;
    padding: 5px;
}
.android-img{
    margin: 60px;
}
.android-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 90px;
}