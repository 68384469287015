.modal--refer{
    text-align: center;
    font-size: 15px;
    padding: 20px 30px 10px 30px ;
    
}

.modal--refer-title{
    text-align: center;
}

.modal-refer-i{
    margin-right: 20px;
    font-size: 30px;
}
.btn-refer{
    font-size: medium;
    margin: 5px;
}

.modal-points{
    font-size: large;
}

.footer-refer{
    text-align: center;
    display: inline-block;
}

.input--form{
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    text-align: center;
}

#modal-refer-id{
    left: 10%;
    width: 80%;
}